import React from "react"

export default function HeroLayoutComponent() {
  return (
    <div className="[&_section>div>div>div]:w-full [&_section>div>div>div]:bg-emerald-50 [&_section>div>div>div]:p-10 [&_section>div>div>div]:text-center [&_section>div>div>div]:text-xs [&_section>div>div>div]:text-slate-500">
      {/*<!-- Component: Three columns even layout --> */}
      <section>
        <div className="container px-6 m-auto">
          <div className="grid grid-cols-4 gap-6 md:grid-cols-8 lg:grid-cols-12">
            <div className="col-span-4">Column 1/3</div>
            <div className="col-span-4">Column 2/3</div>
            <div className="col-span-4">Column 3/3</div>
          </div>
        </div>
      </section>
      {/*<!-- End Three columns even layout --> */}
    </div>
  )
}
