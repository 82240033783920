import React, { useState } from "react"

// Components
import SidebarLayout from "../../../layouts/SidebarLayout"
import Section from "../../../components/section/Section"
import Seo from "../../../components/seo"
import ContentNavigation from "../../../components/scrollspy"

// Libraries / Components for Preview
import PreviewBlock from "../../../components/preview"
import HeroLayoutComponent from "../../../library/components/layout/react/_preview/HeroLayoutComponent"

// Preview
//Even
import LayoutEvenColumnOnePreview from "../../../library/components/layout/react/_preview/even/column-one"
import LayoutEvenColumnsTwoPreview from "../../../library/components/layout/react/_preview/even/columns-two"
import LayoutEvenColumnsThreePreview from "../../../library/components/layout/react/_preview/even/columns-three"
import LayoutEvenColumnsFourPreview from "../../../library/components/layout/react/_preview/even/columns-four"
import LayoutEvenColumnsSixPreview from "../../../library/components/layout/react/_preview/even/columns-six"

//Uneven Two Columns
import LayoutUnevenTwoColumnsFiveSevenPreview from "../../../library/components/layout/react/_preview/uneven/two-columns/columns-five-seven"
import LayoutUnevenTwoColumnsSevenFivePreview from "../../../library/components/layout/react/_preview/uneven/two-columns/columns-seven-five"
import LayoutUnevenTwoColumnsFourEightPreview from "../../../library/components/layout/react/_preview/uneven/two-columns/columns-four-eight"
import LayoutUnevenTwoColumnsEightFourPreview from "../../../library/components/layout/react/_preview/uneven/two-columns/columns-eight-four"
import LayoutUnevenTwoColumnsThreeNinePreview from "../../../library/components/layout/react/_preview/uneven/two-columns/columns-three-nine"
import LayoutUnevenTwoColumnsNineThreePreview from "../../../library/components/layout/react/_preview/uneven/two-columns/columns-nine-three"

//Uneven Three Columns
import LayoutUnevenSixThreeThreeColumnsPreview from "../../../library/components/layout/react/_preview/uneven/three-columns/columns-six-three-three"
import LayoutUnevenThreeSixThreeColumnsPreview from "../../../library/components/layout/react/_preview/uneven/three-columns/columns-three-six-three"
import LayoutUnevenThreeThreeSixColumnsPreview from "../../../library/components/layout/react/_preview/uneven/three-columns/columns-three-three-six"

//Even
const LayoutEvenColumnOneJsx = require("!!raw-loader!../../../library/components/layout/react/even/column-one.jsx")
const LayoutEvenColumnOneHTML = require("!!raw-loader!../../../library/components/layout/html/even/column-one.html")

const LayoutEvenColumnsTwoJsx = require("!!raw-loader!../../../library/components/layout/react/even/columns-two.jsx")
const LayoutEvenColumnsTwoHTML = require("!!raw-loader!../../../library/components/layout/html/even/columns-two.html")

const LayoutEvenColumnsThreeJsx = require("!!raw-loader!../../../library/components/layout/react/even/columns-three.jsx")
const LayoutEvenColumnsThreeHTML = require("!!raw-loader!../../../library/components/layout/html/even/columns-three.html")

const LayoutEvenColumnsFourJsx = require("!!raw-loader!../../../library/components/layout/react/even/columns-four.jsx")
const LayoutEvenColumnsFourHTML = require("!!raw-loader!../../../library/components/layout/html/even/columns-four.html")

const LayoutEvenColumnsSixJsx = require("!!raw-loader!../../../library/components/layout/react/even/columns-six.jsx")
const LayoutEvenColumnsSixHTML = require("!!raw-loader!../../../library/components/layout/html/even/columns-six.html")

//Uneven Two Columns
const LayoutUnevenTwoColumnsFiveSevenJsx = require("!!raw-loader!../../../library/components/layout/react/uneven/two-columns/columns-five-seven.jsx")
const LayoutUnevenTwoColumnsFiveSevenHTML = require("!!raw-loader!../../../library/components/layout/html/uneven/two-columns/columns-five-seven.html")

const LayoutUnevenTwoColumnsSevenFiveJsx = require("!!raw-loader!../../../library/components/layout/react/uneven/two-columns/columns-seven-five.jsx")
const LayoutUnevenTwoColumnsSevenFiveHTML = require("!!raw-loader!../../../library/components/layout/html/uneven/two-columns/columns-seven-five.html")

const LayoutUnevenTwoColumnsFourEightJsx = require("!!raw-loader!../../../library/components/layout/react/uneven/two-columns/columns-four-eight.jsx")
const LayoutUnevenTwoColumnsFourEightHTML = require("!!raw-loader!../../../library/components/layout/html/uneven/two-columns/columns-four-eight.html")

const LayoutUnevenTwoColumnsEightFourJsx = require("!!raw-loader!../../../library/components/layout/react/uneven/two-columns/columns-eight-four.jsx")
const LayoutUnevenTwoColumnsEightFourHTML = require("!!raw-loader!../../../library/components/layout/html/uneven/two-columns/columns-eight-four.html")

const LayoutUnevenTwoColumnsThreeNineJsx = require("!!raw-loader!../../../library/components/layout/react/uneven/two-columns/columns-three-nine.jsx")
const LayoutUnevenTwoColumnsThreeNineHTML = require("!!raw-loader!../../../library/components/layout/html/uneven/two-columns/columns-three-nine.html")

const LayoutUnevenTwoColumnsNineThreeJsx = require("!!raw-loader!../../../library/components/layout/react/uneven/two-columns/columns-nine-three.jsx")
const LayoutUnevenTwoColumnsNineThreeHTML = require("!!raw-loader!../../../library/components/layout/html/uneven/two-columns/columns-nine-three.html")

//Uneven Three Columns
const LayoutUnevenSixThreeThreeColumnsJsx = require("!!raw-loader!../../../library/components/layout/react/uneven/three-columns/columns-six-three-three.jsx")
const LayoutUnevenSixThreeThreeColumnsHTML = require("!!raw-loader!../../../library/components/layout/html/uneven/three-columns/columns-six-three-three.html")

const LayoutUnevenThreeSixThreeColumnsJsx = require("!!raw-loader!../../../library/components/layout/react/uneven/three-columns/columns-three-six-three.jsx")
const LayoutUnevenThreeSixThreeColumnsHTML = require("!!raw-loader!../../../library/components/layout/html/uneven/three-columns/columns-three-six-three.html")

const LayoutUnevenThreeThreeSixColumnsJsx = require("!!raw-loader!../../../library/components/layout/react/uneven/three-columns/columns-three-three-six.jsx")
const LayoutUnevenThreeThreeSixColumnsHTML = require("!!raw-loader!../../../library/components/layout/html/uneven/three-columns/columns-three-three-six.html")

const LayoutsPage = () => {
  const [activeTabs, setActiveTabs] = useState([
    {
      component_name: "LayoutEvenColumnOne",
      title: "One column layout",
      active_tab: 1,
    },
    {
      component_name: "LayoutEvenColumnsTwo",
      title: "Two columns",
      active_tab: 1,
    },
    {
      component_name: "LayoutEvenColumnsThree",
      title: "Three columns",
      active_tab: 1,
    },
    {
      component_name: "LayoutEvenColumnsFour",
      title: "Four columns",
      active_tab: 1,
    },
    {
      component_name: "LayoutEvenColumnsSix",
      title: "Six columns",
      active_tab: 1,
    },
    {
      component_name: "LayoutUnevenTwoColumnsFiveSeven",
      title: "Five & seven columns",
      active_tab: 1,
    },
    {
      component_name: "LayoutUnevenTwoColumnsSevenFive",
      title: "Seven & five columns",
      active_tab: 1,
    },
    {
      component_name: "LayoutUnevenTwoColumnsFourEight",
      title: "Four & eight columns",
      active_tab: 1,
    },
    {
      component_name: "LayoutUnevenTwoColumnsEightFour",
      title: "Eight & four columns",
      active_tab: 1,
    },
    {
      component_name: "LayoutUnevenTwoColumnsThreeNine",
      title: "Three & nine columns",
      active_tab: 1,
    },
    {
      component_name: "LayoutUnevenTwoColumnsNineThree",
      title: "Nine & three columns",
      active_tab: 1,
    },
    {
      component_name: "LayoutUnevenSixThreeThreeColumns",
      title: "Six, three & three columns",
      active_tab: 1,
    },
    {
      component_name: "LayoutUnevenThreeSixThreeColumns",
      title: "Three six & three columns",
      active_tab: 1,
    },
    {
      component_name: "LayoutUnevenThreeThreeSixColumns",
      title: "Three, three & six columns",
      active_tab: 1,
    },
  ])

  const getActiveTabs = (id, tab) => {
    let updatedActiveTabs = activeTabs.map(activeTab => {
      if (activeTab.component_name === id) {
        return { ...activeTab, active_tab: tab }
      }
      return activeTab
    })
    setActiveTabs(updatedActiveTabs)
  }

  return (
    <SidebarLayout>
      <Seo
        title="Tailwind CSS Layouts - WindUI Component Library"
        ogtitle="Tailwind CSS Layouts - WindUI Component Library"
        description="Layout components are a system that allow to visually organize content and adapt to screen size, ensuring consistency. Built with Tailwind CSS by WindUI."
        ogdescription="Layout components are a system that allow to visually organize content and adapt to screen size, ensuring consistency. Built with Tailwind CSS by WindUI."
        url="components/layouts/"
        author="Wind UI"
        keywords="Tailwind CSS, Tailwind CSS Layouts Layouts, Layout Component, WindUI, windui, wind ui"
        robots="index, follow"
        revisit_after="7 days"
      />
      <div className="min-w-0 flex-1">
        {/* Hero Section */}
        <Section ariaLabelledby="intro-heading" id="intro">
          <h1 id="intro-heading">Layouts</h1>
          <p>
            A layout system allows you to visually organize your content and
            adapt to screen size, ensuring consistency and hierarchy.
          </p>

          {/* Hero Preview Section */}
          <section
            className="not-prose max-w-full"
            aria-multiselectable="false"
          >
            <div className="flex w-full items-center justify-center rounded-xl bg-white p-8 shadow-xl shadow-wuiSlate-200 ring-1 ring-wuiSlate-200 sm:p-16 [&>*]:w-full">
              <HeroLayoutComponent />
            </div>
          </section>
        </Section>

        {/* Variations Section*/}
        <Section ariaLabelledby="variations-heading" id="variations-section">
          <h2 id="variations-heading">Variations</h2>

          {/* Layouts Elevated Basic Section */}
          <h3 id="LayoutEvenColumnOne">One Column Layout</h3>
          <PreviewBlock
            id="LayoutEvenColumnOne"
            HtmlComponent={LayoutEvenColumnOneHTML.default}
            JsxComponent={LayoutEvenColumnOneJsx.default}
            getActiveTabs={getActiveTabs}
          >
            <div className="flex w-full items-center justify-center rounded-xl bg-white p-8 sm:p-16 [&>*]:w-full">
              <LayoutEvenColumnOnePreview
                LayoutEvenColumnOne={
                  activeTabs[0].active_tab === 1
                    ? LayoutEvenColumnOneHTML.default
                    : LayoutEvenColumnOneJsx.default
                }
              />
            </div>
          </PreviewBlock>

          {/* Layouts Basic Icon Section*/}
          <h3 id="LayoutEvenColumnsTwo">Two Columns Layout</h3>
          <PreviewBlock
            id="LayoutEvenColumnsTwo"
            HtmlComponent={LayoutEvenColumnsTwoHTML.default}
            JsxComponent={LayoutEvenColumnsTwoJsx.default}
            getActiveTabs={getActiveTabs}
          >
            <div className="flex w-full items-center justify-center rounded-xl bg-white p-8 sm:p-16 [&>*]:w-full">
              <LayoutEvenColumnsTwoPreview
                LayoutEvenColumnsTwo={
                  activeTabs[1].active_tab === 1
                    ? LayoutEvenColumnsTwoHTML.default
                    : LayoutEvenColumnsTwoJsx.default
                }
              />
            </div>
          </PreviewBlock>

          {/* Layouts Elevated Text Icon Section */}
          <h3 id="LayoutEvenColumnsThree">Three Columns Layout</h3>
          <PreviewBlock
            id="LayoutEvenColumnsThree"
            HtmlComponent={LayoutEvenColumnsThreeHTML.default}
            JsxComponent={LayoutEvenColumnsThreeJsx.default}
            getActiveTabs={getActiveTabs}
          >
            <div className="flex w-full items-center justify-center rounded-xl bg-white p-8 sm:p-16 [&>*]:w-full">
              <LayoutEvenColumnsThreePreview
                LayoutEvenColumnsThree={
                  activeTabs[2].active_tab === 1
                    ? LayoutEvenColumnsThreeHTML.default
                    : LayoutEvenColumnsThreeJsx.default
                }
              />
            </div>
          </PreviewBlock>

          {/* Layouts Flat Normal Section */}
          <h3 id="LayoutEvenColumnsFour">Four Columns Layout</h3>
          <PreviewBlock
            id="LayoutEvenColumnsFour"
            HtmlComponent={LayoutEvenColumnsFourHTML.default}
            JsxComponent={LayoutEvenColumnsFourJsx.default}
            getActiveTabs={getActiveTabs}
          >
            <div className="flex w-full items-center justify-center rounded-xl bg-white p-8 sm:p-16 [&>*]:w-full">
              <LayoutEvenColumnsFourPreview
                LayoutEvenColumnsFour={
                  activeTabs[3].active_tab === 1
                    ? LayoutEvenColumnsFourHTML.default
                    : LayoutEvenColumnsFourJsx.default
                }
              />
            </div>
          </PreviewBlock>

          {/* Layouts Flat Icon Section */}
          <h3 id="LayoutEvenColumnsSix">Six Columns Layout</h3>
          <PreviewBlock
            id="LayoutEvenColumnsSix"
            HtmlComponent={LayoutEvenColumnsSixHTML.default}
            JsxComponent={LayoutEvenColumnsSixJsx.default}
            getActiveTabs={getActiveTabs}
          >
            <div className="flex w-full items-center justify-center rounded-xl bg-white p-8 sm:p-16 [&>*]:w-full">
              <LayoutEvenColumnsSixPreview
                LayoutEvenColumnsSix={
                  activeTabs[4].active_tab === 1
                    ? LayoutEvenColumnsSixHTML.default
                    : LayoutEvenColumnsSixJsx.default
                }
              />
            </div>
          </PreviewBlock>

          {/* Layouts Flat Text Icon Section */}
          <h3 id="LayoutUnevenTwoColumnsFiveSeven">
            Two Uneven Columns Five & Seven{" "}
          </h3>
          <PreviewBlock
            id="LayoutUnevenTwoColumnsFiveSeven"
            HtmlComponent={LayoutUnevenTwoColumnsFiveSevenHTML.default}
            JsxComponent={LayoutUnevenTwoColumnsFiveSevenJsx.default}
            getActiveTabs={getActiveTabs}
          >
            <div className="flex w-full items-center justify-center rounded-xl bg-white p-8 sm:p-16 [&>*]:w-full">
              <LayoutUnevenTwoColumnsFiveSevenPreview
                LayoutUnevenTwoColumnsFiveSeven={
                  activeTabs[5].active_tab === 1
                    ? LayoutUnevenTwoColumnsFiveSevenHTML.default
                    : LayoutUnevenTwoColumnsFiveSevenJsx.default
                }
              />
            </div>
          </PreviewBlock>

          {/* Layouts Flat Text Icon Section */}
          <h3 id="LayoutUnevenTwoColumnsSevenFive">
            Two Uneven Columns Seven & Five{" "}
          </h3>
          <PreviewBlock
            id="LayoutUnevenTwoColumnsSevenFive"
            HtmlComponent={LayoutUnevenTwoColumnsSevenFiveHTML.default}
            JsxComponent={LayoutUnevenTwoColumnsSevenFiveJsx.default}
            getActiveTabs={getActiveTabs}
          >
            <div className="flex w-full items-center justify-center rounded-xl bg-white p-8 sm:p-16 [&>*]:w-full">
              <LayoutUnevenTwoColumnsSevenFivePreview
                LayoutUnevenTwoColumnsSevenFive={
                  activeTabs[6].active_tab === 1
                    ? LayoutUnevenTwoColumnsSevenFiveHTML.default
                    : LayoutUnevenTwoColumnsSevenFiveJsx.default
                }
              />
            </div>
          </PreviewBlock>

          {/* Layouts Flat Text Icon Section */}
          <h3 id="LayoutUnevenTwoColumnsFourEight">
            Two Uneven Columns Four & Eight
          </h3>
          <PreviewBlock
            id="LayoutUnevenTwoColumnsFourEight"
            HtmlComponent={LayoutUnevenTwoColumnsFourEightHTML.default}
            JsxComponent={LayoutUnevenTwoColumnsFourEightJsx.default}
            getActiveTabs={getActiveTabs}
          >
            <div className="flex w-full items-center justify-center rounded-xl bg-white p-8 sm:p-16 [&>*]:w-full">
              <LayoutUnevenTwoColumnsFourEightPreview
                LayoutUnevenTwoColumnsFourEight={
                  activeTabs[7].active_tab === 1
                    ? LayoutUnevenTwoColumnsFourEightHTML.default
                    : LayoutUnevenTwoColumnsFourEightJsx.default
                }
              />
            </div>
          </PreviewBlock>

          {/* Layouts Flat Text Icon Section */}
          <h3 id="LayoutUnevenTwoColumnsEightFour">
            Two Uneven Columns Eight & Four
          </h3>
          <PreviewBlock
            id="LayoutUnevenTwoColumnsEightFour"
            HtmlComponent={LayoutUnevenTwoColumnsEightFourHTML.default}
            JsxComponent={LayoutUnevenTwoColumnsEightFourJsx.default}
            getActiveTabs={getActiveTabs}
          >
            <div className="flex w-full items-center justify-center rounded-xl bg-white p-8 sm:p-16 [&>*]:w-full">
              <LayoutUnevenTwoColumnsEightFourPreview
                LayoutUnevenTwoColumnsEightFour={
                  activeTabs[8].active_tab === 1
                    ? LayoutUnevenTwoColumnsEightFourHTML.default
                    : LayoutUnevenTwoColumnsEightFourJsx.default
                }
              />
            </div>
          </PreviewBlock>

          {/* Layouts Flat Text Icon Section */}
          <h3 id="LayoutUnevenTwoColumnsThreeNine">
            Two Uneven Columns Three & Nine
          </h3>
          <PreviewBlock
            id="LayoutUnevenTwoColumnsThreeNine"
            HtmlComponent={LayoutUnevenTwoColumnsThreeNineHTML.default}
            JsxComponent={LayoutUnevenTwoColumnsThreeNineJsx.default}
            getActiveTabs={getActiveTabs}
          >
            <div className="flex w-full items-center justify-center rounded-xl bg-white p-8 sm:p-16 [&>*]:w-full">
              <LayoutUnevenTwoColumnsThreeNinePreview
                LayoutUnevenTwoColumnsThreeNine={
                  activeTabs[9].active_tab === 1
                    ? LayoutUnevenTwoColumnsThreeNineHTML.default
                    : LayoutUnevenTwoColumnsThreeNineJsx.default
                }
              />
            </div>
          </PreviewBlock>

          {/* Layouts Flat Text Icon Section */}
          <h3 id="LayoutUnevenTwoColumnsNineThree">
            Two Uneven Columns Nine & Three
          </h3>
          <PreviewBlock
            id="LayoutUnevenTwoColumnsNineThree"
            HtmlComponent={LayoutUnevenTwoColumnsNineThreeHTML.default}
            JsxComponent={LayoutUnevenTwoColumnsNineThreeJsx.default}
            getActiveTabs={getActiveTabs}
          >
            <div className="flex w-full items-center justify-center rounded-xl bg-white p-8 sm:p-16 [&>*]:w-full">
              <LayoutUnevenTwoColumnsNineThreePreview
                LayoutUnevenTwoColumnsNineThree={
                  activeTabs[10].active_tab === 1
                    ? LayoutUnevenTwoColumnsNineThreeHTML.default
                    : LayoutUnevenTwoColumnsNineThreeJsx.default
                }
              />
            </div>
          </PreviewBlock>

          {/* Layouts Flat Text Icon Section */}
          <h3 id="LayoutUnevenSixThreeThreeColumns">
            Three Uneven Columns Six Three Three
          </h3>
          <PreviewBlock
            id="LayoutUnevenSixThreeThreeColumns"
            HtmlComponent={LayoutUnevenSixThreeThreeColumnsHTML.default}
            JsxComponent={LayoutUnevenSixThreeThreeColumnsJsx.default}
            getActiveTabs={getActiveTabs}
          >
            <div className="flex w-full items-center justify-center rounded-xl bg-white p-8 sm:p-16 [&>*]:w-full">
              <LayoutUnevenSixThreeThreeColumnsPreview
                LayoutUnevenSixThreeThreeColumns={
                  activeTabs[11].active_tab === 1
                    ? LayoutUnevenSixThreeThreeColumnsHTML.default
                    : LayoutUnevenSixThreeThreeColumnsJsx.default
                }
              />
            </div>
          </PreviewBlock>

          {/* Layouts Flat Text Icon Section */}
          <h3 id="LayoutUnevenThreeSixThreeColumns">
            Three Uneven Columns Three Six Three
          </h3>
          <PreviewBlock
            id="LayoutUnevenThreeSixThreeColumns"
            HtmlComponent={LayoutUnevenThreeSixThreeColumnsHTML.default}
            JsxComponent={LayoutUnevenThreeSixThreeColumnsJsx.default}
            getActiveTabs={getActiveTabs}
          >
            <div className="flex w-full items-center justify-center rounded-xl bg-white p-8 sm:p-16 [&>*]:w-full">
              <LayoutUnevenThreeSixThreeColumnsPreview
                LayoutUnevenThreeSixThreeColumns={
                  activeTabs[12].active_tab === 1
                    ? LayoutUnevenThreeSixThreeColumnsHTML.default
                    : LayoutUnevenThreeSixThreeColumnsJsx.default
                }
              />
            </div>
          </PreviewBlock>

          {/* Layouts Flat Text Icon Section */}
          <h3 id="LayoutUnevenThreeThreeSixColumns">
            Three Uneven Columns Three Three Six
          </h3>
          <PreviewBlock
            id="LayoutUnevenThreeThreeSixColumns"
            HtmlComponent={LayoutUnevenThreeThreeSixColumnsHTML.default}
            JsxComponent={LayoutUnevenThreeThreeSixColumnsJsx.default}
            getActiveTabs={getActiveTabs}
          >
            <div className="flex w-full items-center justify-center rounded-xl bg-white p-8 sm:p-16 [&>*]:w-full">
              <LayoutUnevenThreeThreeSixColumnsPreview
                LayoutUnevenThreeThreeSixColumns={
                  activeTabs[13].active_tab === 1
                    ? LayoutUnevenThreeThreeSixColumnsHTML.default
                    : LayoutUnevenThreeThreeSixColumnsJsx.default
                }
              />
            </div>
          </PreviewBlock>
        </Section>

        {/* Usage Section*/}
        <Section ariaLabelledby="usage-heading" id="usage-section">
          <h2 id="usage-heading">Usage</h2>
          <p>
            Use any of the layouts to ensure that your content will be visual
            consistent on all screen resolutions.
          </p>
        </Section>

        {/* Specs Section */}
        <Section ariaLabelledby="specs-heading" id="specs">
          <h2 id="specs-heading">Specs</h2>

          <p>The layout consists of the following elements:</p>
          <ul>
            <li>
              <strong>Container: </strong> the container keeps the content at
              the center of the screen with a 2rem (32px) horizontal margin (to
              avoid having the content at the edge of the screen). You can
              remove this class to create layouts that would expand to the full
              width of the screen.
            </li>
            <li>
              <strong>Row: </strong> a grid element that uses a 12-column layout
              for large screens, 8 columns for medium screens and 4 columns for
              small screens. In all layouts, a 1.5rem (24px) gutter is
              separating the columns from each other.
            </li>
            <li>
              <strong>Column: </strong> all content is placed within columns.
            </li>
          </ul>
        </Section>
      </div>

      <ContentNavigation variations={activeTabs} />
    </SidebarLayout>
  )
}

export default LayoutsPage
