import React from "react"
import CopyComponent from "../../../../../../components/copy/CopyComponent"

export default function LayoutEvenColumnOnePreview(props) {
  const { LayoutEvenColumnOne } = props
  return (
    <div className="[&_section>div>div>div]:w-full [&_section>div>div>div]:bg-emerald-50 [&_section>div>div>div]:p-10 [&_section>div>div>div]:text-center [&_section>div>div>div]:text-xs [&_section>div>div>div]:text-slate-500">
      <CopyComponent
        copyToClipboardCode={LayoutEvenColumnOne}
        componentName="LayoutEvenColumnOne"
        extraClasses="[&_section]:w-full"
      >
        {/*<!-- Component: One column even layout --> */}
        <section>
          <div className="container px-6 m-auto">
            <div className="grid grid-cols-4 gap-6 md:grid-cols-8 lg:grid-cols-12">
              <div className="col-span-4 md:col-span-8 lg:col-span-12">
                Column 1/1
              </div>
            </div>
          </div>
        </section>
        {/*<!-- End One column even layout --> */}
      </CopyComponent>
    </div>
  )
}
