import React from "react"
import CopyComponent from "../../../../../../../components/copy/CopyComponent"

export default function LayoutUnevenTwoColumnsEightFourPreview(props) {
  const { LayoutUnevenTwoColumnsEightFour } = props
  return (
    <div className="[&_section>div>div>div]:w-full [&_section>div>div>div]:bg-emerald-50 [&_section>div>div>div]:p-10 [&_section>div>div>div]:text-center [&_section>div>div>div]:text-xs [&_section>div>div>div]:text-slate-500">
      <CopyComponent
        copyToClipboardCode={LayoutUnevenTwoColumnsEightFour}
        componentName="LayoutUnevenTwoColumnsEightFour"
        extraClasses="[&_section]:w-full"
      >
        {/*<!-- Component: Eight and Four uneven columns layout --> */}
        <section>
          <div className="container px-6 m-auto">
            <div className="grid grid-cols-4 gap-6 md:grid-cols-8 lg:grid-cols-12">
              <div className="col-span-4 lg:col-span-8">Column 8/12</div>
              <div className="col-span-4">Column 4/12</div>
            </div>
          </div>
        </section>
        {/*<!-- End Eight and Four uneven columns layout --> */}
      </CopyComponent>
    </div>
  )
}
